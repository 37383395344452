import { Component, OnInit } from '@angular/core';
import { ApiService } from '@core/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-verified',
  templateUrl: './verified.component.html',
  styleUrls: ['./verified.component.scss']
})
export class VerifiedComponent implements OnInit {
  public  contentHeader :object
  searchtext;
  rows : any = []
  image :any=[]
  data_loading :any

  count :any
  page : any ='1'
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
  };
  constructor(public service : ApiService , public modalService : NgbModal) {
    this.data_loading = true
    this.service.get_verified().subscribe(res=>{
      console.log(res);
      this.count = res['count']   
      this.rows = res['data']
      this.data_loading = false
    })
   }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Verified',
      actionButton: true,

    };
  }

  showimage(modalImage,row, rowIndex){
    this.image=[]
   this.modalService.open(modalImage, {
     size: 'md',
     centered: true,
     windowClass: ' modal-Image'
   });
   this.image = row.image
 }

 showidcard(idcard,row, rowIndex , data){
  this.image=[]
 this.modalService.open(idcard, {
   size: 'md',
   centered: true,
   windowClass: ' modal-Image'
 });
 
  this.image = data
 
}


onPage(event){
  this.data_loading = true
  this.page= event.offset +1;
  this.service.get_verified_by_page(this.page ,'').subscribe(res=>{
    console.log(res);
    this.rows = res["data"]
    this.data_loading = false
  })
}




clearFilter(event){
  this.data_loading = true
  this.rows = []
  this.searchtext = ''
  this.searchtext = event.target.value  
  this.service.get_verified_by_page(this.page,this.searchtext).subscribe(res=>{
    this.rows = res["data"]
    this.data_loading = false
  })
}
}
