import { formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "@core/api.service";
import { DataServiceService } from "@core/data-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { SwiperOptions } from "swiper";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {
  basicSelectedOption: any = "10";
  public contentHeader: object;
  public selected = [];
  public rateUser: FormGroup;
  user_object_id :any
  // name , email , number , location , paid or unpaid
  public columns = [];
  loading :any
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
  };
  data_loading :any
  searchtext;
  user_paid : any = 'yes'

  row_id: any;

  image: any;

  rows: any = [];

  room_type : any = 'regular'
  room_check :any = 1500
  // public count;
  // public pageSize = 10;
  // public limit = 10;
  // public offset = 0;
  count :any
  page : any = '1';
  data_ser ;
  transId:any
  paymentStatus:any = 'Sucess' 
  checkoutRequestId:any
  amount:any
  subscribe_date:any = new Date()
  manual:any= '1'

  constructor(
    public service: ApiService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    public data: DataServiceService,
    public router: Router,
    public toastx: ToastrService
  ) {

    this.data_loading = true
    this.service.get_user_list("1" , '').subscribe((res) => {   
       this.count = res['count']   
      this.rows = res["data"]
      this.data_loading = false
    });
  }

  onPage(event){
    this.data_loading = true
    this.page= event.offset +1;
    this.service.get_user_list(this.page ,'').subscribe(res=>{
      this.rows = res["data"]
      this.data_loading = false
    })
  }

  ngOnInit() {
    this.contentHeader = {
      headerTitle: "Users-List",
      actionButton: true,
    };

    this.rateUser = this._formBuilder.group({
      rate: ["", Validators.required],
    });
  }


  clearFilter(event){
    this.data_loading = true
    this.rows = []
    this.searchtext = ''
    this.searchtext = event.target.value  
    this.service.get_user_list(this.page,this.searchtext).subscribe(res=>{
      this.rows = res["data"]
      this.data_loading = false
    })
  }


  


  showimage(modalImage, row, rowIndex, value) {
    this.image = [];
    this.modalService.open(modalImage, {
      size: "lg",
      centered: true,
      windowClass: " modal-Image",
    });
    this.image =row.image;
    
  }

  payment(vip, row, rowIndex, value){
    this.modalService.open(vip, {
      size: "lg",
      centered: true,
      windowClass: " modal-Image",
    });
    this.checkoutRequestId= 'ws_CO_'+row.phone
    this.user_object_id = row.id

  }
  
  showsingle(modalSingle, row, rowIndex, value){
     this.image = []
    this.modalService.open(modalSingle, {
      size: 'md',
      centered: true,
      windowClass: ' modal-Image'
    });
    
    this.image = 'https://meet.locatestudent.com/api/images/'+value
  }

  rate(rateModal, row, rowIndex) {
    this.modalService.open(rateModal, {
      size: "sm",
      centered: true,
      windowClass: " modal-Image",
    });

    console.log(row);
    this.row_id = row.id;

    
  }

  paid(event){
    this.user_paid = event.target.value
  }

  vipRoom(event){
   this.room_type = event.target.value
   
    if(event.target.value == 'regular'){
      this.room_check = 1500
    }
    else if (event.target.value =='daily' ){
      this.room_check = 10
    }
    else if (event.target.value == 'monthly'){
      this.room_check = 200
    }
    else if (event.target.value == 'vip'){
      this.room_check = 200
    }
    else if (event.target.value == 'vvip'){
    this.room_check = 10000
    }

    

  }

  submit_room_type(){
    let formateddate = formatDate(this.subscribe_date, 'yyyy-MM-dd', 'en-US');    
    this.transId = Math.floor(100000 + Math.random() * 900000);
    this.loading = true
    this.service.room_update(this.user_object_id, this.user_paid, this.room_type).subscribe(res=>{
      if(res['result'] == true){
        this.service.payment_subscriptions(this.user_object_id, this.transId, this.paymentStatus, this.checkoutRequestId, this.room_check,formateddate, this.manual).subscribe(res=>{
          console.log(res)
         })
        this.toastx.success('Updated Sucessfully')
        this.loading = false
        this.service.get_user_list("1" ,'').subscribe((res) => {
          console.log(res);
          this.rows = res["data"];
        });
        this.modalService.dismissAll();
      }
    })
  }

  onSubmit(value) {
    this.service.rate_user(value.rate, this.row_id).subscribe((res) => {
      if (res["result"] == true) {
        this.toastx.success("Ratting added succesfully");
      }
      this.modalService.dismissAll();
    });
  }

  userdetails(row, rowIndex) {
    this.data.selectedObject = row;
    this.data.saveData();
    this.router.navigate(["user-details"]);
  }

  onSelect({ selected }) {
    console.log("Select Event", selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  add() {
    this.selected.push(this.rows[1], this.rows[3]);
  }

  update() {
    this.selected = [this.rows[1], this.rows[3]];
  }

  remove() {
    this.selected = [];
  }

  displayCheck(row) {
    return row.name !== "Ethel Price";
  }

  onactive(status, id, i) {
    console.log(this.rows[i]);
    this.service.active_inactive(status, id).subscribe((res) => {
      if (res["result"] == true) {
        this.rows[i].status = status;
        this.toastx.error("User Inactivated");
      }
    });
  }



  oninactive(status, id, i) {
    this.service.active_inactive(status, id).subscribe((res) => {
      if (res["result"] == true) {
        this.rows[i].status = status;
        this.toastx.success("User Activated");
      }
    });
  }
}
