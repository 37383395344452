import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddChatroomComponent } from './add-chatroom/add-chatroom.component';
import { ChatRoomlistComponent } from './chat-roomlist/chat-roomlist.component';
import { RouterModule } from '@angular/router';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreCommonModule } from '@core/common.module';
import { ToastrModule } from 'ngx-toastr';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// import { NgxSpinnerModule } from "ngx-spinner";
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


const routes = [
  {
    path: 'add-chatroom',
    component: AddChatroomComponent,
    data: { animation: 'add-chatroom' }
  },
  {
    path: 'chatroom-list',
    component: ChatRoomlistComponent,
    data: { animation: 'chatroom-list' }
  },
 
];

@NgModule({
  declarations: [
   AddChatroomComponent, ChatRoomlistComponent,

  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    // BrowserAnimationsModule,
    ContentHeaderModule,
    NgxDatatableModule,
    NgbModule,
    ReactiveFormsModule,
    CoreCommonModule,
    AutocompleteLibModule,
    ToastrModule,
    // SpinnersAngularModule
    // NgxSpinnerModule
    
    
  ],
  exports: [AddChatroomComponent, ChatRoomlistComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChatRoomModule { }
