import { Component, OnInit } from '@angular/core';
import { ApiService } from '@core/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-unverified',
  templateUrl: './unverified.component.html',
  styleUrls: ['./unverified.component.scss']
})
export class UnverifiedComponent implements OnInit {

  public  contentHeader :object
  searchtext ;
  rows : any = []
  image :any = []
  data_loading :any
  count :any
  page :any ='1'
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
  };
  row_id :any
  loading :any
  public rateUser: FormGroup;
  constructor(public service : ApiService , public modalService : NgbModal ,  private _formBuilder: FormBuilder,public toastx : ToastrService) {
    this.data_loading = true
    this.service.get_unverified().subscribe(res=>{
      console.log(res);
      this.rows = res['data']
      this.count = res['count']   
      this.data_loading = false
    })
   }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Un-Verified',
      actionButton: true,

    };

    this.rateUser = this._formBuilder.group({
      rate: ["", Validators.required],
    });
  }


  showimage(modalImage,row, rowIndex){
    this.image=[]
   this.modalService.open(modalImage, {
     size: 'lg',
     centered: true,
     windowClass: ' modal-Image'
   });
   this.image = row.image
 }

 showidcard(idcard,row, rowIndex , data){
  this.image=[]
 this.modalService.open(idcard, {
   size: 'lg',
   centered: true,
   windowClass: ' modal-Image'
 });
 
  this.image = data
 
}


onPage(event){
  this.data_loading = true
  this.page= event.offset +1;
  this.service.get_unverified_by_page(this.page ,'').subscribe(res=>{
    console.log(res);
    this.rows = res["data"]
    this.data_loading = false
  })
 

}

clearFilter(event){
  this.data_loading = true
  this.rows = []
  this.searchtext = ''
  this.searchtext = event.target.value  
  this.service.get_unverified_by_page(this.page,this.searchtext).subscribe(res=>{
    console.log(res);
    this.rows = res["data"]
    this.data_loading = false
  })
}


rate(rateModal, row, rowIndex) {
  this.modalService.open(rateModal, {
    size: "sm",
    centered: true,
    windowClass: " modal-Image",
  });

  console.log(row);
  this.row_id = row.id;

  
}


onSubmit(value) {
  this.loading = true
  this.service.rate_user(value.rate, this.row_id).subscribe((res) => {
    this.loading = false
    if (res["result"] == true) {
      this.toastx.success("Ratting added succesfully");
      this.ngOnInit()
    }
    this.modalService.dismissAll();
  });
}


}
