<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <section id="basic-horizontal-layouts">
      <form
        class="auth-login-form mt-2"
        [formGroup]="addChatroom"
        (ngSubmit)="onSubmit(addChatroom.value)"
      >
        <div class="row match-height">
          <div class="col-12 col-md-12 card">
            <div class="row">
              <div class="col-6">
                <div class="form-group row">
                  <div class="col-sm-9" id="basicSelect">
                    <label class="lable_arabic" for="first-name">Section</label>
                    <select
                      class="form-control"
                      id="basicSelect"
                      (change)="section($event)"
                    >
                      <option value="data.id" *ngFor="let data of section_list">
                        {{ data.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div>
                  <div class="form-group row">
                    <div class="col-sm-9">
                      <label for="first-name">Name</label>
                      <input
                        type="text"
                        id="propertyname"
                        class="form-control"
                        name="fname"
                        formControlName="name"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group row">
                  <div class="col-sm-9" (change)="uploadFile($event)">
                    <label for="upload"> Picture </label>
                    <div class="upload_div" aria-hidden="true">
                      <!-- <i class="icn" for="file-input" type="file" data-feather="upload-cloud"  (change)="uploadFile($event)"></i> -->
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        multiple
                        style="width: 100%; height: 100%"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" style="width: 80%">
                  <div class="col-4" *ngIf="this.images != undefined">
                    <!-- <div class="btn btn-danger " class="del_icon">
                                            <i data-feather="trash-2"></i>
                                        </div> -->
                    <img
                      [src]="this.images"
                      height="100"
                      width="100"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div>
                  <div class="form-group row">
                    <div class="col-sm-9">
                      <label for="first-name">Location</label>
                      <div>
                        <ng-autocomplete
                          name="fname"
                          formControlName="location"
                          [data]="searchData"
                          [searchKeyword]="keyword"
                          placeholder="Select country"
                          (selected)="selectEvent($event)"
                          (inputChanged)="onChangeSearch($event)"
                          [itemTemplate]="itemTemplate"
                          [notFoundTemplate]="notFoundTemplate"
                        >
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.display_name"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group row">
                  <div class="col-sm-9" id="basicSelect">
                    <label class="lable_arabic" for="first-name"
                      >Room Type</label
                    >
                    <select
                      class="form-control"
                      id="basicSelect"
                      (change)="vipRoom($event)"
                    >
                      <option value="regular">Regular</option>
                      <option value="vip">VIP</option>
                      <option value="vvip">VVIP</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="!lockradius_hide">
                <div>
                  <div class="form-group row">
                    <div class="col-sm-9">
                      <label for="first-name"> Lock Radius </label>
                      <input
                        type="text"
                        id="propertyname"
                        [(ngModel)]="lock_radius"
                        class="form-control"
                        name="fname"
                        formControlName="lockradius"
                        placeholder="Lock Radius"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-dark" [disabled]="loading">
                  <span *ngIf="!loading">Submit</span>
                  <span *ngIf="loading">Loading ..</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</div>
