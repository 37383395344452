import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor() {
   }

   startTimer(intervalTime: number) {
    return interval(intervalTime);
  }
}
