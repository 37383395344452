import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@core/api.service';
import { colors } from 'app/colors.const';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent implements OnInit {
  contentHeader : any
  data :any =[]
  total_chatrooms: any
  date_today :any
  @ViewChild('earningChartRef') earningChartRef: any;
  users :any
  public earningChartoptions;
  public isMenuToggled = false;
  private $earningsStrokeColor2 = '#28c76f66';
  private $earningsStrokeColor3 = '#3ea2e1';
  constructor(public service : ApiService) { 
    this.service.admin_dashboard().subscribe(res=>{
      console.log(res);
       this.data = res
      this.users = this.data.total_user
      console.log(this.users);
      
      this.total_chatrooms = this.data.total_chatrooms
      this.date_today = this.data.date_today

      
    })
  }

  ngOnInit(): void {


    this.contentHeader = {
      headerTitle: 'Dashboard',
      actionButton: true,
    }

    this.earningChartoptions = {
      chart: {
        type: 'pie',
        height: 120,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [53, 16, 31],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['App', 'Service', 'Product'],
      stroke: { width: 0 },
      colors: [this.$earningsStrokeColor2, this.$earningsStrokeColor3, colors.solid.success],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: 'App',
                formatter: function (w) {
                  return '53%';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };
  }
    

}
