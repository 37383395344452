<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="basic-horizontal-layouts">
          <div class="row match-height">
            <div class="col-md-12 col-12 card">
              <div class="row" style="width: 100%">
                <div class="col-md-6 col-12">
                  <div
                    class="d-flex justify-content-between align-items-center m-1"
                  >
                 
                  </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                  <div
                    class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0 mb-2"
                  >
                    <label class="d-flex align-items-center"
                      >Search<input
                        type="search"
                        placeholder="Search"
                        class="form-control ml-25"
                        [(ngModel)]="searchtext"
                        autocomplete="off"
                    /></label>
                  </div>
                </div>
              </div>
              <ngx-datatable #table class="bootstrap core-bootstrap" [columnMode]="'force'" [headerHeight]="40"
                [rowHeight]="58" [footerHeight]="50" rowHeight="auto" [limit]="6" [rows]="rows" [scrollbarH]="true">
                <ngx-datatable-column name="Picture" prop="picture" [width]="50">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <img
                      class="  mr-1"  style="border-radius: 7px;"
                      src="https://meet.locatestudent.com/api/images/{{row.image}}"
                      height="50"
                      width="50"
                    />
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Section" prop="section" [width]="50">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <span>{{row.section}}</span>
                    </div>
                  </ng-template>  
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" prop="name" [width]="100">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <span>{{row.name}}</span>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Location" prop="location" [width]="400">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <span>{{row.location}}</span>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Lock & Radius" prop="lock" [width]="100">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <span>{{row.lock_radius}}</span>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Room Type" prop="lock" [width]="100">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <span style="text-transform: capitalize;">{{row.room_type}}</span>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action" prop="action" [width]="100">
                  <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                     <button class="btn btn-primary" (click)="modalOpenDanger(modalDanger, row,rowIndex)">Delete</button>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <!-- room_type -->
              </ngx-datatable>
          </div>
        </div>
        </section>
        <sa-spinner-circular [enabled]="false"></sa-spinner-circular>

</div>


</div>


<!-- Del modal -->
<ng-template #modalDanger let-modal>
  <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel160">Delete Section</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body" tabindex="1" ngbAutofocus>
      Are you sure you want to delete this Chat Section?
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="del()" rippleEffect>
          Accept
      </button>
      <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
          Decline
      </button>
  </div>
</ng-template>
<!-- Del modal -->