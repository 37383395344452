<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="basic-horizontal-layouts">
            <form class="auth-login-form mt-2" [formGroup]="addSection" (ngSubmit)="onSubmit(addSection.value)">
            <div class="row match-height">
                <div class="col-md-12 col-12 card">
                    <div class="col-6">
                        <div class="form-group row">
                            <div class="col-sm-9">
                                
                                <label for="first-name">Section</label>
                                <input type="text" id="propertyname" class="form-control" name="fname"
                                    formControlName="section" placeholder="Section name" />
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-dark">Add Section</button>
                    </div>
                </div>

            </div>
        </form>
        </section>
    </div>

</div>