import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@core/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss']
})
export class SectionListComponent implements OnInit {
  public contentHeader :any
  admin_id : any
  sections_list : any =[]
  section_name : any
  section_id : any
  public updateSection: FormGroup;
  index: any;
  arr : any =[]
  constructor(public service : ApiService , public router : Router, public modalService : NgbModal,private _formBuilder: FormBuilder,
    public toastr : ToastrService) {
    this.admin_id = window.localStorage.getItem('meet_admin_id')
    console.log(this.admin_id);
    
   }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Section',
      actionButton: true,

   

    };

    this.updateSection = this._formBuilder.group({
      section: ['', Validators.required]
    });


    this.service.get_section().subscribe(res=>{
      console.log(res);
       this.sections_list = res['data']
       
    })
  }


  modalOpenDanger(modalDanger,data, index) {
    console.log(data);
    this.index = index
      this.section_id = data.id
    this.modalService.open(modalDanger, {
      centered: true,
      windowClass: 'modal modal-danger'
    });
  }

  del(){
   this.service.delete_section(this.section_id).subscribe(res=>{
    console.log(res); 
    if(res['result']==true){
      this.toastr.success('Deleted Sucessdfully')
     this.sections_list.splice(this.index, 1)
    this.modalService.dismissAll()
    }
   })
  }



  modalOpenEdit(modalEdit , data){
    console.log(data);
    
    this.section_name = data.name
    this.section_id = data.id
    console.log(this.section_id);
    
    this.modalService.open(modalEdit, {
      centered: true,
      windowClass: 'modal modal-sucess'
    });
  }



  onSubmit(value){
    console.log(value);
    this.service.edit_section(value.section ,this.section_id).subscribe(res=>{
      console.log(res);
      
    })
  }

}
