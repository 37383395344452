<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="col-lg-12 col-12 p-0">
      <div class="card">
        <div
          class="card-body d-flex justify-content-center align-items-center flex-column"
        >
          <div
            class="d-flex justify-content-start align-items-center mb-1 flex-column"
          >
            <!-- avatar -->
            <div class="avatar mr-1">
              <img *ngIf="profile == null || profile== '' " src="../../../../assets/images/profile.png"     height="100"
              width="100">
              <img 
              *ngIf="profile" 
                src="https://meet.locatestudent.com/api/images/{{profile}}"
                alt="avatar img"
                height="100"
                width="100"
              />
            </div>
            <!--/ avatar -->
            <div class="profile-user-info">
              <h6 class="mb-0 font-medium-2 text-center mt-2">{{ name }}</h6>
              <h6 class="text-muted font-medium-2 mt-1">{{ email }}</h6>
            </div>
            <div
              class="row w-100 d-flex justify-content-center align-items-center mt-2"
            >
              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Gender</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{ gender }}</p>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">DOB</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{ dob }}</p>
                </div>
              </div>
              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Paid</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{ paid }}</p>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Phone</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{ phone }}</p>
                </div>
              </div>
              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Bio</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{ bio }}</p>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Interests</p>
                </div>


                <div>
                  <div class="badge badge-pill  badge-light-primary" *ngFor="let data of interests">
                    <p   class="card-text black text-right">{{ data }}</p>
                    <!-- <p  *ngIf="!data" class="card-text black text-right">nothing</p> -->
                  </div>
                </div>
                
              </div>
              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Address</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{ address }}</p>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Rate number</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{ rate_number }}</p>
                </div>
              </div>
              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Kin</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{kin}}</p>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Kin id</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{kin_id_number}}</p>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Kin relation</p>
                </div>

                <div>
                  <p class="card-text black text-right">{{kin_relation}}</p>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Selfie</p>
                </div>

                <div>
                  <div
                    class="avatar"
                    (click)="showimage(modalImage, selfie_img)"
                  >
                     <img *ngIf="selfie_img == null || selfie_img== '' " src="../../../../assets/images/profile.png"  width="42">
                    <img *ngIf="selfie_img"
                      src="https://meet.locatestudent.com/api/images/{{ selfie_img }}"
                      alt="avatar img"
                      height="40"
                      width="40"
                    />
                  </div>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Id Card Front</p>
                </div>

                <div>
                  <div
                    class="avatar"
                    (click)="showimage(modalImage, id_card_front_img)"
                  >
                  <img *ngIf="id_card_front_img == null || id_card_front_img== '' " src="../../../../assets/images/profile.png"  width="42">
                    <img *ngIf="id_card_front_img"
                      src="https://meet.locatestudent.com/api/images/{{
                        id_card_front_img
                      }}"
                      alt="avatar img"
                      height="40"
                      width="40"
                    />
                  </div>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Id Card Back</p>
                </div>

                <div>
                  <div
                    class="avatar"
                    (click)="showimage(modalImage, id_card_back_img)"
                  >

                  <img *ngIf="id_card_back_img == null || id_card_back_img== '' " src="../../../../assets/images/profile.png"  width="42">

                    <img *ngIf="id_card_back_img"
                      src="https://meet.locatestudent.com/api/images/{{
                        id_card_back_img
                      }}"
                      alt="avatar img"
                      height="40"
                      width="40"
                    />
                  </div>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>

                  <p class="card-text">National Doc</p>
                </div>

                <div>
                  <div class="avatar">
                    <img *ngIf="national_doc == null || national_doc== '' " src="../../../../assets/images/profile.png"  width="42">

                    <img
                     *ngIf="national_doc"
                     src="https://meet.locatestudent.com/api/images/{{
                      national_doc
                      }}"
                      alt="avatar img"
                      height="40"
                      width="40"
                    />
                  </div>
                </div>
              </div>

              <div
                class="col-12 d-flex justify-content-between align-items-center border-bottom mb-2"
              >
                <div>
                  <p class="card-text">Rent Receipt</p>
                </div>

                <div>
                    <a
                      class="card-text black"
                      href="https://meet.locatestudent.com/api/images/{{rent_receipt}}"
                      download="true"
                      target="_blank"
                      style="text-decoration: underline"
                      >View</a
                    >
                </div>
              </div>

             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalImage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Profile</h4>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center">
    <img src="{{ image }}" style="width: 60%; height: 600px" />
  </div>
</ng-template>
