import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { SectionListComponent } from './section-list/section-list.component';
import { AddSectionComponent } from './add-section/add-section.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
const routes = [
  {
    path: 'add-section',
    component:AddSectionComponent ,
    data: { animation: 'add-section' }
  },
  {
    path: 'section-list',
    component: SectionListComponent,
    data: { animation: 'section-list' }
  },
 
];

@NgModule({
  declarations: [
    SectionListComponent, AddSectionComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ContentHeaderModule, TranslateModule, CoreCommonModule,
    NgxDatatableModule,
    CardSnippetModule,NgbProgressbarModule,ReactiveFormsModule,NgbModule,ToastrModule
  ],
  exports: [SectionListComponent, AddSectionComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class SectionModule { }
