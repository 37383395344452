<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <section id="basic-horizontal-layouts">
        <div class="row match-height">
          <div class="col-md-12 col-12 card">
            <div class="row" style="width: 100%">
              <div class="col-md-6 col-12">
                <div
                  class="d-flex justify-content-between align-items-center m-1"
                >
               
                </div>
              </div>
              <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div
                  class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0 mb-2"
                >
                  <label class="d-flex align-items-center"
                    >Search<input
                      type="search"
                      placeholder="Search"
                      class="form-control ml-25"
                      [(ngModel)]="searchtext"
                      autocomplete="off"
                  /></label>
                </div>
              </div>
            </div>
            
            <ngx-datatable
              #table
              class="bootstrap core-bootstrap"
              [columnMode]="'force'"
              [headerHeight]="40"
              [rowHeight]="58"
              [footerHeight]="50"
              rowHeight="auto"
              [limit]="10"
              [rows]="rows"
              [scrollbarH]="true"
            >
            
            <ngx-datatable-column name="Reported by profile" prop="reported by">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <div
                  class="d-flex align-items-center"
                  style="cursor: pointer"
                  (click)="showimage(modalImage,row,rowIndex,row.report_by)"
                >
                <img [src]="row.report_by.thumb ? row.report_by.thumb : '../../../../assets/images/profile.png'" width="40" height="40" />

                  <!-- <img src="{{row.report_by.thumb}}" width="40" height="40" /> -->
                </div>
              </ng-template>
            </ngx-datatable-column>
              <ngx-datatable-column name="Reported by" prop="reported by">
                <ng-template
                  let-row="row"
                  let-rowIndex="rowIndex"
                  ngx-datatable-cell-template
                >
                  <span (click)="userdetails(row, rowIndex, row.report_by)" class="user_name cursor-pointer">{{row.report_by.username}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Reported to Ptofile" prop="reported by">
                <ng-template
                  let-row="row"
                  let-rowIndex="rowIndex"
                  ngx-datatable-cell-template
                >
                  <div
                    class="d-flex align-items-center"
                    style="cursor: pointer"
                    (click)="showimage(modalImage,row,rowIndex,row.report_to)"
                  >
                    <img src="{{row.report_to.thumb}}" width="40" height="40" />
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Reported To" prop="reported to">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="d-flex align-items-center">
                    <span class="user_name cursor-pointer" (click)="userdetails(row, rowIndex, row.report_to)">{{ row.report_to.username }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
  
              <ngx-datatable-column name="Reason" prop="reason">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="d-flex align-items-center">
                    <span class="email">{{ row.reason}}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <!-- <ngx-datatable-column name="Status" prop="status">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="d-flex align-items-center">
                    <span>{{ row.phone }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column> -->
             
            </ngx-datatable>
          </div>
        </div>
      </section>
    </div>
  </div>
  
  <ng-template #modalImage let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Profile</h4>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body d-flex justify-content-center align-items-center">
      <swiper [config]="config" [pagination]="true" class="swiper_div">
        <div *ngFor="let data of image" class="center">
          <img
            [src]="'https://meet.locatestudent.com/api/images/' + data"
            width="60%"
            height="700"
          />
        </div>
      </swiper>
    </div>
  </ng-template>
  
  <ng-template #rateModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Rate User</h4>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body d-flex justify-content-center align-items-center">
      <form class="auth-login-form mt-2" [formGroup]="rateUser" (ngSubmit)="onSubmit(rateUser.value)">
        <div class="form-group row">
          <label for="first-name">Rate</label>
          <input
            type="number"
            id="propertyname"
            class="form-control"
            name="fname"
            formControlName="rate"
            placeholder="Rate Out Of 10"
          />
          <button class="btn btn-primary mt-2">
            <span>Submit</span>
          </button>
        </div>
      </form>
    </div>
    
  </ng-template>
  <!-- wrmgwkrmg -->
  