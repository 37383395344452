import { Injectable } from "@angular/core";
import { Http, Headers, RequestOptions } from "@angular/http";
import { map, retry } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  link: any;
  constructor(private router: Router, public http: Http) {
    this.link = "https://meet.locatestudent.com/api/api.php";
  }

  admin_login(email, password): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "admin_login");
    postData.append("email", email);
    postData.append("password", password);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  users_list(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_data");
    postData.append("table_name", "users");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  add_section(name, id): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "add_data");
    postData.append("table_name", "sections");
    postData.append("name", name);
    postData.append("admin_id", id);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  get_section(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_data");
    postData.append("table_name", "sections");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  edit_section(heading, id): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "update_data");
    postData.append("table_name", "sections");
    postData.append("name", heading);
    postData.append("id", id);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  delete_section(id): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "delete_data");
    postData.append("table_name", "sections");
    postData.append("id", id);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  add_chat_room(
    name,
    image,
    location,
    lock_radius,
    vip_room,
    section,
    lat,
    lng
  ): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "add_data");
    postData.append("table_name", "chat_rooms");
    postData.append("name", name);
    postData.append("image", image);
    postData.append("location", location);
    postData.append("lock_radius", lock_radius);
    postData.append("room_type", vip_room);
    postData.append("section", section);
    postData.append("lat", lat);
    postData.append("lng", lng);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  get_chat_room(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_data");
    postData.append("table_name", "chat_rooms");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  dell_chat_room(id): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "delete_data");
    postData.append("table_name", "chat_rooms");
    postData.append("id", id);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }
  rate_user(rate_number, id): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "update_data");
    postData.append("table_name", "users");
    postData.append("rate_number", rate_number);
    postData.append("id", id);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  active_inactive(status, id): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "update_data");
    postData.append("table_name", "users");
    postData.append("status", status);
    postData.append("id", id);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  admin_reported_user(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "admin_reported_user");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  admin_meet_detail(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "admin_meet_detail");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  admin_show_sos(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "admin_show_sos");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }
  seen_sos(id): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "seen_sos");
    postData.append("id", id);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }


  admin_dashboard(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "admin_dashboard");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  payment_subscriptions(id, transId,paymentStatus, checkoutRequestId, amount, subscribe_date,manual):Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "add_data");
    postData.append("table_name", "payment_subscriptions");
    postData.append("user_id", id);  
    postData.append("transId", transId);
    postData.append("paymentStatus", paymentStatus);
    postData.append("checkoutRequestId", checkoutRequestId);
    postData.append("amount", amount);
    postData.append("subscribe_date", subscribe_date);
    postData.append("manual", manual);

    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }
  
  room_update(id,paid,paid_type): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "update_data");
    postData.append("table_name", "users");
    postData.append("id", id);
    postData.append("paid", paid);
    postData.append("paid_type", paid_type);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  get_user_list(page,search): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_user_list");
    postData.append("page", page);
    postData.append("search", search);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }


  peding_verification(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_pending_verification");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  peding_verification_by_page(page,search ): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_pending_verification");
    postData.append("page", page);
    postData.append("search", search);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  get_unverified(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_unverified");
    // postData.append("page", page);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }
  
  get_unverified_by_page(page,search): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_unverified");
    postData.append("page", page);
    postData.append("search", search);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  get_verified(): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_verified");
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }
  get_verified_by_page(page ,search): Observable<any[]> {
    let postData = new FormData();
    postData.append("type", "get_verified");
    postData.append("page", page);
    postData.append("search", search);
    return this.http.post(this.link, postData).pipe(map((res) => res.json()));
  }

  

}
