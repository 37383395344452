<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <section>
        <div class="content-wrapper container-xxl p-0">
          <div class="content-body">
            <!-- Card Advance -->
            <div class="row match-height">
              <div class="col-md-6 col-lg-3 col-sm-12">
                <div class="card"  style="cursor: pointer;">
                  <div class="card-header d-flex flex-column align-items-start pb-0">
                    <div class="avatar bg-light-primary p-50 m-0">
                      <div class="avatar-content">
                        <i data-feather="book" class="font-medium-5"></i>
                      </div>
                    </div>
                    <div class="right_align">
                      <h2 class="font-weight-bolder mt-1">Users</h2>
                      <p class="font-weight-bolder mt-1" style="text-align: right;font-size:16px">{{users}}</p>
                    </div>
                    <div style="width: 100%;margin-top: 50px;">
                        <ngb-progressbar  type="primary" [value]="users"></ngb-progressbar>
                    </div>
                    </div>
                  </div>
                </div>
             
              <div class="col-md-6 col-lg-3 col-sm-12">
                <div class="card" style="cursor: pointer;">
                  <div class="card-header d-flex flex-column align-items-start pb-0">
                    <div class="avatar bg-light-primary p-50 m-0">
                      <div class="avatar-content">
                        <i data-feather="users" class="font-medium-5"></i>
  
                      </div>
                    </div>
                    <div class="right_align">
                      <h2 class="font-weight-bolder mt-1">Chat Rooms</h2>
                      <p class="font-weight-bolder mt-1" style="text-align: right;font-size:16px">{{total_chatrooms}}</p>
                    </div>
                    <div style="width: 100%;margin-top: 50px; ">
                        <ngb-progressbar   type="primary" [value]="total_chatrooms"></ngb-progressbar>
                    </div>
                    <!-- <p class="mb-0">Subscribers Gained</p> -->
                  </div>
  
                  <!-- Apexchart component-->
                  <div id="gainedChartoptions">
                  </div>
                </div>
              </div>
              
             
               
              <div class="col-md-6 col-lg-3 col-sm-12">
                <div class="card" style="cursor: pointer;">
                  <div class="card-header d-flex flex-column align-items-start pb-0">
                    <div class="avatar bg-light-primary p-50 m-0">
                      <div class="avatar-content">
                        <i data-feather="users" class="font-medium-5"></i>
                      </div>
                    </div>
                    <div class="right_align">
                      <h2 class="font-weight-bolder mt-1">Today</h2>
                      <p class="font-weight-bolder mt-1" style="text-align: right;font-size:16px">{{date_today}}</p>
                    </div>
                      <div style="width: 100%;margin-top: 50px;">
                        <ngb-progressbar  type="primary" [value]="date_today"></ngb-progressbar>
                    </div>
                  </div>

                </div>
                
              </div>
              <!-- <div class="col-md-9 col-lg-4 col-sm-12">
                <div class="card earnings-card" style="height: auto !important;">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <h4 class="card-title mb-1">Most Visitors</h4>
                        <div class="font-small-2">Most vistors of this month is ABC chat room</div>
                        <h5 class="mb-1">$4055.56</h5>
                        <p class="card-text text-muted font-small-2">
                          <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>
                        </p>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12">
                        <div id="earnings-donut-chart" #earningChartRef>
                          Apexchart component
                          <apx-chart
                            [chart]="{
                              type: 'donut',
                              width:
                                isMenuToggled === false ? earningChartRef.offsetWidth : earningChartoptions.chart.width,
                              height: 120,
                              toolbar: {
                                show: false
                              }
                            }"
                            [dataLabels]="earningChartoptions.dataLabels"
                            [series]="earningChartoptions.series"
                            [legend]="earningChartoptions.legend"
                            [labels]="earningChartoptions.labels"
                            [stroke]="earningChartoptions.stroke"
                            [colors]="earningChartoptions.colors"
                            [grid]="earningChartoptions.grid"
                            [plotOptions]="earningChartoptions.plotOptions"
                          ></apx-chart>
                          / Apexchart component
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              

              
             
              
            </div>
        </div>
    </div>
      </section>

      <!--/ Earnings Chart -->

    </div>

</div>




      
  
  
  