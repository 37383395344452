import { Component, OnInit } from "@angular/core";
// import * as feather from 'feather-icons';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "@core/api.service";
@Component({
  selector: "app-add-chatroom",
  templateUrl: "./add-chatroom.component.html",
  styleUrls: ["./add-chatroom.component.scss"],
})
export class AddChatroomComponent implements OnInit {
  public contentHeader: any;
  basicSelectedOption;
  show_img: any;
  base64String: any;
  images: any ;
  image_ref: any = [];
  section_list: any;
  select_section: any;
  select_vip_room: any;
  search_loaction : any
  displayName :any =[]
  diplaylist: boolean = false
  data : any =[]
  keyword :any
  diplay_data :any = []
  searchData:any = []
  lat:any
  lng:any
  loading:any
  public lockradius_hide = false;
   lock_radius = '10'
  public addChatroom: FormGroup;

  constructor(
    public service: ApiService,
    public router: Router,
    public modalService: NgbModal,
    private _formBuilder: FormBuilder,
    public toastr: ToastrService
  ) {
    this.select_vip_room = 'regular'
  }


  selectEvent(item) {
    console.log(item)
    this.displayName = item.display_name
    this.lat = item.lat
    this.lng = item.lon
  }

  onChangeSearch(event) {
    console.log(event);
    this.search_loaction = event
    
    this.keyword = 'display_name';
    let url = 'https://nominatim.openstreetmap.org/search?q='+this.search_loaction+'&format=json&polygon_geojson=1&addressdetails=1'
    fetch(url).then((response) => response.json())
  .then(data =>{   
    this.searchData = data
  });
  
  }
  

  

  ngOnInit(): void {
   
    // feather.replace();
    this.contentHeader = {
      headerTitle: "Add Chat Room",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Add Property",
            isLink: true,
            link: "/add-property",
          },
        ],
      },
    };

    this.service.get_section().subscribe((res) => {
      this.section_list = res["data"];
      this.select_section = this.section_list[0].id
    });

    this.addChatroom = this._formBuilder.group({
      name: ["", Validators.required],
      location: ["", Validators.required],
      lockradius: ["", Validators.required],
    });
  }

  uploadFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        encoding: this.base64String;
        reader.onload = (event: any) => {
          console.log(event.target.result);
          this.images =  event.target.result
          // this.images.push({ img: event.target.result });
        };
        reader.readAsDataURL(event.target.files[i]);
        this.show_img = true;
      }
    }
  }

  section(event) {
    this.select_section = event.target.value;
  }

  vipRoom(event) {
    this.select_vip_room = event.target.value;
    this.lockradius_hide = (this.select_vip_room  === 'vip' || this.select_vip_room  === 'vvip');
  }

  del(i) {
    this.images.splice(i, 1);
    this.image_ref.splice(i, 1);
    console.log(this.image_ref);
  }

  onSubmit(value) {
    console.log(value)
    if(value.name == undefined || !value.name ){
      this.toastr.error('Please enter name')
    }else if(this.images == undefined){
      this.toastr.error('Please add image')
    }else if(value.location == undefined || value.location == null){
      this.toastr.error('Please enter location')
    }else if(this.lock_radius == undefined || this.lock_radius == null){
      this.toastr.error('Please enter lock radius')
    }else{
      this.loading = true
    this.service.add_chat_room(value.name, this.images, this.displayName,this.lock_radius,this.select_vip_room, this.select_section, this.lat , this.lng).subscribe((res) => {
        console.log(res);
        this.loading = false
        if(res['result'] = true){
          this.toastr.success('Added Sucessfully')
          this.addChatroom.reset()
          this.router.navigate(['chatroom-list']);
        }
      });
  }

  }
}
