import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  selectedObject: any;
  constructor() { }


  saveData() {
    localStorage.setItem('selectedObject', JSON.stringify(this.selectedObject));
  }

  loadData() {
    const data = localStorage.getItem('selectedObject');
    if (data) {
      this.selectedObject = JSON.parse(data);
    }
  }
}

