import { Component, OnInit } from '@angular/core';
import { DataServiceService } from '@core/data-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  public contentHeader: object;
  user_data: any = [];
  email : any
  name : any
  paid :any
  gender : any
  phone :any
  address : any
  bio :any
  interests :any =[]
  rate_number : any
  dob :any
  national_doc : any
  rent_receipt :any
  kin :any
  kin_id_number :any
  kin_relation :any
  image :any
  selfie_img :any
  id_card_front_img :any
  id_card_back_img :any
  profile :any
  image_defult :any
  constructor(public data : DataServiceService ,private modalService: NgbModal) {
    this.data.loadData();
    this.user_data = this.data.selectedObject
    this.email = this.user_data.email
    this.name = this.user_data.name
    this.paid = this.user_data.paid
    this.gender = this.user_data.gender
    this.phone = this.user_data.phone
    this.address =  this.user_data.address
    this.bio = this.user_data.bio
    if (this.user_data.interests && this.user_data.interests !== "") {
      this.interests = JSON.parse(this.user_data.interests);
    } else {
      this.interests = this.user_data.interests;
    }
  
    this.rate_number = this.user_data.rate_number
    this.dob = this.user_data.dob
    this.national_doc = this.user_data.national_doc
    this.rent_receipt = this.user_data.rent_receipt
    this.kin = this.user_data.kin
    this.kin_id_number = this.user_data.kin_id_number
    this.kin_relation  =this.user_data.kin_relation
    this.selfie_img = this.user_data.selfie
    this.id_card_front_img = this.user_data.id_card_front
    this.id_card_back_img = this.user_data.id_card_back

    if(this.user_data.image == null){
      this.image_defult = true
    }
    else{
      this.image_defult = false
      this.profile = this.user_data.image[0]
    }

     console.log(this.user_data);
     
  }

  ngOnInit() {

    this.contentHeader = {
      headerTitle: 'User Details',
      actionButton: true,

    };
  }

  showimage(modalImage, value){
     console.log(value);
     this.image = []
    this.modalService.open(modalImage, {
      size: 'md',
      centered: true,
      windowClass: ' modal-Image'
    });
    
    this.image = 'https://meet.locatestudent.com/api/images/'+value
  }

}
