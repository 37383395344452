import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { UserListComponent } from './user-list/user-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ReportedUsersComponent } from './reported-users/reported-users.component';
import { DatingComponent } from './dating/dating.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { SwiperModule } from 'ngx-swiper-wrapper';

const routes = [
  {
    path: 'dash-board',
    component: DashBoardComponent,
    
  },
  {
    path: 'user-list',
    component: UserListComponent,
  },
  {
    path: 'reported-users',
    component: ReportedUsersComponent,
  },
  {
    path: 'dating',
    component: DatingComponent,
  },
  {
    path: 'user-details',
    component: UserDetailsComponent,
  },
 
];

@NgModule({
  declarations: [
    DashBoardComponent,
    UserListComponent,
    ReportedUsersComponent,
    DatingComponent,
    UserDetailsComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ContentHeaderModule, TranslateModule, CoreCommonModule,
    NgxDatatableModule,
    CardSnippetModule,NgbProgressbarModule,
    NgApexchartsModule,
    SwiperModule,
  ],
  exports: [DashBoardComponent, UserListComponent ,ReportedUsersComponent,DatingComponent,UserDetailsComponent]
})
export class AdminModule { }
