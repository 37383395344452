import { Component, OnInit } from "@angular/core";
import { ApiService } from "@core/api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-chat-roomlist",
  templateUrl: "./chat-roomlist.component.html",
  styleUrls: ["./chat-roomlist.component.scss"],
})
export class ChatRoomlistComponent implements OnInit {
  basicSelectedOption: any = "10";
  public contentHeader: object;
  public selected = [];
  public rows = [];
  chatroom_id: any;
  index: any;
  searchtext ;
  // name , email , number , location , paid or unpaid
  public columns = [
    { name: "Picture", prop: "picture" },
    { name: "Section", prop: "section" },
    { name: "Name", prop: "name" },
    { name: "Loaction", prop: "location" },
    { name: "Lock & Radius", prop: "lock/Raius" },
    { name: "Room Type", prop: "room_type" },
  ];

  public count = 100;
  public pageSize = 3;
  public limit = 5;
  public offset = 0;

  public changeLimit(event): void {
    this.limit = parseInt(event.target.value);
    console.log(this.limit);
  }

  public onPage(event): void {
    console.log(event);
    this.count = event.count;
    this.pageSize = event.pageSize;
    this.limit = event.limit;
    this.offset = event.offset;
  }

  constructor(
    public service: ApiService,
    public modalService: NgbModal,
    public toastr: ToastrService
  ) {
    // this.fetch((data) => {
    //   this.rows = data;
    // });
  }

  ngOnInit() {
    this.contentHeader = {
      headerTitle: "Chat Room List",
      actionButton: true,
    };

    this.service.get_chat_room().subscribe((res) => {
      this.rows = res["data"];
    });
  }

  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open("GET", `assets/data/company.json`);

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }

  onSelect({ selected }) {
    console.log("Select Event", selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  remove() {
    this.selected = [];
  }

  displayCheck(row) {
    return row.name !== "Ethel Price";
  }

  modalOpenDanger(modalDanger, data, index) {
    this.index = index;
    this.chatroom_id = data.id;
    this.modalService.open(modalDanger, {
      centered: true,
      windowClass: "modal modal-danger",
    });
  }

  del() {
    this.service.dell_chat_room(this.chatroom_id).subscribe((res) => {
      if (res['result'] == true) {
        this.rows.splice(this.index, 1);
        this.toastr.success("Deleted Sucessdfully");
        this.modalService.dismissAll();
      }
    });
  }
}
