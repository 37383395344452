import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@core/api.service';
import { DataServiceService } from '@core/data-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'app-reported-users',
  templateUrl: './reported-users.component.html',
  styleUrls: ['./reported-users.component.scss']
})
export class ReportedUsersComponent implements OnInit {
  basicSelectedOption : any = '10'
  public contentHeader: object;
  public selected = [];
  public rateUser: FormGroup;
  // name , email , number , location , paid or unpaid
  public columns = [];

  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
  };
  image:any
  searchtext;

  rows :any = []


  public count = 100;
  public pageSize = 10;
  public limit = 8;
  public offset = 0;


  public changeLimit(event): void {
    this.limit = parseInt(event.target.value);
    console.log(this.limit);
  }

  public onPage(event): void {
    console.log(event);
    this.count = event.count;
    this.pageSize = event.pageSize;
    this.limit = event.limit;
    this.offset = event.offset;
  }
  constructor(public service : ApiService ,private modalService: NgbModal ,
    private _formBuilder: FormBuilder, public router :Router,
    public data : DataServiceService) {
    this.service.admin_reported_user().subscribe(res=>{
     console.log(res);
     this.rows = res['reported']
    })

 
    }

  ngOnInit(){ 

    this.contentHeader = {
      headerTitle: 'Users-List',
      actionButton: true,

    };

    this.rateUser = this._formBuilder.group({
      rate: ['', Validators.required]
    });
  }

  showimage(modalImage,row, rowIndex , value){
    console.log(value);
    this.image=[]
   this.modalService.open(modalImage, {
     size: 'md',
     centered: true,
     windowClass: ' modal-Image'
   });
   this.image = value.image
   console.log( this.image);
 }

 userdetails(row , rowIndex ,value){
  this.data.selectedObject = value
  this.data.saveData();
  this.router.navigate(['user-details'])
 }


  rate(rateModal,row, rowIndex){
    this.modalService.open(rateModal, {
      size: 'lg',
      centered: true,
      windowClass: ' modal-Image'
    });

    console.log(row);
  }

  onSubmit(value){
     console.log(value);
     
  }




 

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  add() {
    this.selected.push(this.rows[1], this.rows[3]);
  }

  update() {
    this.selected = [this.rows[1], this.rows[3]];
  }

  remove() {
    this.selected = [];
  }

  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
}
