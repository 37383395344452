import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'dash-board',
    title: 'Dashboard',
    type: 'item',
    icon: 'home',
    url: 'dash-board'
  },
  {
    id: 'user-list',
    title: 'Users',
    type: 'item',
    icon: 'users',
    url: 'user-list'
  },


  {
    id: 'Sections',
    title: 'Sections',
    type: 'collapsible',
    icon: 'folder-minus',

    children :[
      {
      id: 'add-section',
      title: 'Add Section',
      type: 'item',
      icon: 'file-plus',
      url: 'add-section'
    },
    {
      id: 'section-list',
      title: 'Section List',
      type: 'item',
      icon: 'list',
      url: 'section-list'
    }
    
    ]
  },

  {
    id: 'chat-room',
    title: 'Chat Room',
    type: 'collapsible',
    icon: 'message-circle',

    children :[
      {
      id: 'add-chatroom',
      title: 'Add Chat Room',
      type: 'item',
      icon: 'file-plus',
      url: 'add-chatroom'
    },
    {
      id: 'chatroom-list',
      title: 'Chat Room List',
      type: 'item',
      icon: 'list',
      url: 'chatroom-list'
    }
    
    ]

  },

  {
    id: 'reported-users',
    title: 'Reported Users',
    type: 'item',
    icon: 'users',
    url: 'reported-users'
  },
  {
    id: 'dating',
    title: 'Meet Users',
    type: 'item',
    icon: 'users',
    url: 'dating'
  },

  { 
    id: ' Verification',
    title: 'Verification',
    type: 'collapsible',
    icon: 'check-circle',

    children :[
      {
      id: 'pending-verification',
      title: 'Pending Verification',
      type: 'item',
      icon: 'alert-triangle',
      url: 'pending-verification'
    },
    {
      id: 'un-verified',
      title: 'Unverified',
      type: 'item',
      icon: 'x-circle',
      url: '/un-verified'
    },
    {
      id: 'verified',
      title: 'Verified',
      type: 'item',
      icon: 'check-square',
      url: 'verified'
    }
    
    ]

  },

]
