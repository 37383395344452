<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section id="basic-horizontal-layouts">

      
      <div class="row match-height">
        <div class="col-md-12 col-12 card">
          <div class="row" style="width: 100%">
            <div class="col-md-6 col-12">
              <div
                class="d-flex justify-content-between align-items-center m-1"
              >
             
              </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
              <div
                class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0 mb-2"
              >
                <label class="d-flex align-items-center"
                  >Search<input
                    type="search"
                    placeholder="Search"
                    class="form-control ml-25"
                    [(ngModel)]="searchtext"
                    autocomplete="off"
                /></label>
              </div>
            </div>
          </div>
          <ngx-datatable
            #table
            class="bootstrap core-bootstrap"
            [columnMode]="'force'"
            [headerHeight]="40"
            [rowHeight]="58"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="10"
            [rows]="rows"
            [scrollbarH]="true"
          >
            <ngx-datatable-column name="Meet to Profile" prop="image">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <div
                  class="d-flex align-items-center"
                  style="cursor: pointer"
                  (click)="showimage(modalImage, row, rowIndex, row.meet_to)"
                >  
                  <img src="{{ row?.meet_to.thumb }}" width="40" height="40" />
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Meet to" prop="meet to">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <div
                  style="cursor: pointer"
                  class="d-flex align-items-center"
                  (click)="userdetails(row, rowIndex, row.meet_to)"
                >
                  <span class="user_name">{{ row.meet_to.name }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Meet By Profile" prop="image">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <div
                  class="d-flex align-items-center"
                  style="cursor: pointer"
                  (click)="showimage(modalImage, row, rowIndex, row.meet_by)"
                > 
                <img style="object-fit: cover;"  [src]="row.meet_by.thumb ? row.meet_by.thumb : '../../../../assets/images/profile.png'" width="40" height="40" />
                
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Meet by" prop="meet by" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div
                  style="cursor: pointer"
                  class="d-flex align-items-center"
                  (click)="userdetails(row, rowIndex, row.meet_by)"
                >
                  <span class="user_name">{{ row.meet_by.name }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Meet Location" prop="meet_Location"  [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span class="loc">{{ row.location }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Meet Date" prop="meet_date">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span>{{ row.meet_date }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Meet Time" prop="last_location">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span>{{ row.meet_time }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Meet Phone" prop="phone">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span>{{ row.meet_phone }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Last Location" prop="last_location">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span>{{ row.last_location }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </section>
  </div>
</div>

<ng-template #modalImage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Profile</h4>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center">
    <swiper [config]="config" [pagination]="true" class="swiper_div">
      <div *ngFor="let data of image" class="center">
        <img *ngIf="data == null || data == undefined" src="../../../../assets/images/profile.png" alt="">
        <img *ngIf="data"
          [src]="'https://meet.locatestudent.com/api/images/' + data"
          width="60%"
          height="700"
        />
      </div>
    </swiper>
  </div>
</ng-template>

<ng-template #rateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Rate User</h4>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center">
    <form
      class="auth-login-form mt-2"
      [formGroup]="rateUser"
      (ngSubmit)="onSubmit(rateUser.value)"
    >
      <div class="form-group row">
        <label for="first-name">Rate</label>
        <input
          type="number"
          id="propertyname"
          class="form-control"
          name="fname"
          formControlName="rate"
          placeholder="Rate Out Of 10"
        />
        <button class="btn btn-primary mt-2">
          <span>Submit</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
<!-- wrmgwkrmg -->
