
   <div class="modal-header text-white" >
    <div class="modal-title " id="modal-basic-title" >
      <h4>SOS</h4>
    </div>    
    <button type="button" class="close" aria-label="Close" (click)="close()" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body  p-3">
      <p>{{data.message}}</p>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="help()" >Help</button>
  </div>