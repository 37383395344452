<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section id="basic-horizontal-layouts" >

      <div class="row match-height">
        <div class="col-md-12 col-12 card">
          <span *ngIf="data_loading" class="spinner-grow mt-1"></span>
          <div class="row" style="width: 100%">
            <div class="col-md-6 col-12">
              <div
                class="d-flex justify-content-between align-items-center m-1"
              >
              </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
              <div
                class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0 mb-2"
              >
                <label class="d-flex align-items-center"
                  >Search
                  <input
                    type="search"
                    placeholder="Search"
                    class="form-control ml-25"
                    [(ngModel)]="searchtext"
                    autocomplete="off"
                    (keydown)="clearFilter($event)"
                />
              </label>
              </div>
            </div>
          </div>
          <ngx-datatable
            #table
            class="bootstrap core-bootstrap"
            [columnMode]="'force'"
            [headerHeight]="40"
            [rowHeight]="58"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="10"
            [rows]="rows"
            [scrollbarH]="true"
            [externalPaging]="true"
            [count]="count"

          
            (page)="onPage($event)"
          >
            <ngx-datatable-column name="Image" prop="image">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
                
              >
                <div
                  class="d-flex align-items-center"
                  style="cursor: pointer"
                  (click)="showimage(modalImage, row, rowIndex , row.thumb)"
                >
                <img style="object-fit:cover;" *ngIf="row.thumb"  src="{{row.thumb}}" width="40" height="40" />
                <img style="object-fit:cover;" *ngIf="!row.thumb ||row.thumb== null" src='./../../../assets/images/profile.png' width="40" height="40" />
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="name">
              <ng-template let-row="row" let-rowIndex="index" ngx-datatable-cell-template>
                <div style="cursor: pointer" class="d-flex align-items-center" (click)="userdetails(row ,rowIndex )">
                  <span class="user_name">{{ row.name }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Email" prop="email">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span class="email">{{ row.email }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Phone" prop="phone">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span>{{ row.phone }}</span>
                </div>
              </ng-template>
              </ngx-datatable-column>
      
        
            <ngx-datatable-column name="Paid" prop="paid">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span>{{ row.paid }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
          
            <ngx-datatable-column name="address" prop="Address">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span>{{ row.address }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <span *ngIf="row.status == '1'">Inactive</span>
                  <span *ngIf="row.status == '0'">Active</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Action" prop="action_btn" [width]="230">
              <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <button  *ngIf="row.status == '0'" class="btn btn-primary" (click)="onactive('1' ,row.id , rowIndex)">
                    <span>Active</span>
                  </button>
                  <button  *ngIf="row.status == '1'" class="btn btn-danger" (click)="oninactive('0',row.id , rowIndex)">
                    <span>Inactive</span>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Rate" prop="rate">
              <ng-template
                let-row="row"
                Index="rowIndex"
                ngx-datatable-cell-template
              >
                <div class="d-flex align-items-center">
                  <button
                    class="btn btn-primary"
                    (click)="rate(rateModal, row, rowIndex)"
                  >
                    <span>Rate</span>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Pay" prop="pay">
              <ng-template
                let-row="row"
                Index="rowIndex"
                ngx-datatable-cell-template
              >
                <div class="d-flex align-items-center">
                  <button
                    class="btn btn-primary"
                    (click)="payment(vip, row, rowIndex)"
                  >
                    <span>Make pay</span>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </section>
  </div>
</div>

<ng-template #modalImage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Profile</h4>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center">
    <swiper [config]="config" [pagination]="true" class="swiper_div">
      <div *ngFor="let data of image" class="center">
          <img [src]="'https://meet.locatestudent.com/api/images/'+data" width="60%" height="700" />
      </div>
  </swiper>

  </div>
</ng-template>

<ng-template #modalSingle let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Profile</h4>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center">
    <img src="{{ image }}" style="width: 60%; height: 600px" />
  </div>
</ng-template>

<ng-template #rateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Rate User</h4>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center">
    <form class="auth-login-form mt-2" [formGroup]="rateUser" (ngSubmit)="onSubmit(rateUser.value)">
      <div class="form-group row">
        <label for="first-name">Rate</label>
        <input
          type="number"
          id="propertyname"
          class="form-control"
          name="fname"
          formControlName="rate"
          placeholder="Rate Out Of 10"
        />
        <button class="btn btn-primary mt-2">
          <span>Submit</span>
        </button>
      </div>
    </form>
  </div>
  
</ng-template>
<!-- wrmgwkrmg -->



<ng-template #vip let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Rate User</h4>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center w-100">
        <div class="form-group row w-75">
          <div class="col-12" id="basicSelect">
            <label class="lable_arabic" for="first-name"
              >Payment</label
            >
            <select
              class="form-control"
              id="basicSelect"
              (change)="vipRoom($event)"
            >
              <option value="regular">Regular</option>
              <option value="daily">Daily</option>
              <option value="monthly">Monthly</option>
              <option value="vip">VIP</option>
              <option value="vvip">VVIP</option>
            </select>
          </div>  
          <div class=" col-12 mt-2" id="basicSelect">
            <label class="lable_arabic" for="first-name"
              >Paid</label
            >
            <select
              class="form-control"
              id="basicSelect"
              (change)="paid($event)"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>


          <div class="col-12">
            <button (click)="submit_room_type()" class="btn btn-primary mt-2 ">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              <span>Submit</span>
            </button>
          </div>

          
         
        </div>
        
  </div>
  
</ng-template>
