<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <section id="basic-horizontal-layouts">
        <div class="row match-height">
          
          <div class="col-md-12 col-12 card">
            <span *ngIf="data_loading" class="spinner-grow mt-1"></span>

            <div class="row" style="width: 100%">
              <div class="col-md-6 col-12">

                <div
                  class="d-flex justify-content-between align-items-center m-1"
                >
               
                </div>
              </div>
              <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div
                  class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0 mb-2"
                >
                  <label class="d-flex align-items-center"
                    >Search<input
                      type="search"
                      placeholder="Search"
                      class="form-control ml-25"
                      [(ngModel)]="searchtext"
                      [ngModelOptions]="{standalone:true}"
                      (keydown)="clearFilter($event)"
                      autocomplete="off"
                  /></label>
                </div>
              </div>
            </div>
            
            <ngx-datatable
              #table
              class="bootstrap core-bootstrap"
              [columnMode]="'force'"
              [headerHeight]="40"
              [rowHeight]="58"
              [footerHeight]="50"
              rowHeight="auto"
              [limit]="10"
              [rows]="rows"
              [scrollbarH]="true"
              [externalPaging]="true"
              [count]="count"
  
            
              (page)="onPage($event)"
            >
            
            <ngx-datatable-column name="Profile" prop="Profile">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <div
                  class="d-flex align-items-center"
                  style="cursor: pointer"
                  (click)="showimage(modalImage,row,rowIndex)"
                >
                <img [src]="row.thumb ? row.thumb : '../../../../assets/images/profile.png'" width="40" height="40" />
                </div>
              </ng-template>
            </ngx-datatable-column>
              <ngx-datatable-column name="Username" prop="Username">
                <ng-template
                  let-row="row"
                  let-rowIndex="rowIndex"
                  ngx-datatable-cell-template
                >
                  <span class="user_name cursor-pointer">{{row.username}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Id-Card-Front" prop="id_card_front">
                <ng-template
                  let-row="row"
                  let-rowIndex="rowIndex"
                  ngx-datatable-cell-template
                >
                  <div
                    class="d-flex align-items-center"
                    style="cursor: pointer"
                    (click)="showidcard(idcard,row,rowIndex,row.id_card_front)"
                  >
                    <img src="{{row.url}}{{row.id_card_front}}" width="40" height="40" />
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Id-Card-Back" prop="id_card_front">
                <ng-template
                  let-row="row"
                  let-rowIndex="rowIndex"
                  ngx-datatable-cell-template
                >
                  <div
                    class="d-flex align-items-center"
                    style="cursor: pointer"
                    (click)="showidcard(idcard,row,rowIndex,row.id_card_back)"
                  >
                    <img src="{{row.url}}{{row.id_card_back}}" width="40" height="40" />
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="National doc" prop="reported to">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="d-flex align-items-center">
                    <span class="user_name cursor-pointer">
                       {{row.national_doc}}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
  
              <ngx-datatable-column name="Selfie" prop="selfie">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="d-flex align-items-center"  (click)="showidcard(idcard,row, rowIndex, row.selfie)">
                    <img src={{row.url}}{{row.selfie}}   style="cursor: pointer" width="40" height="40">
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Rent-Receipt" prop="status">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="d-flex align-items-center" (click)="showidcard(idcard,row, rowIndex, row.rent_receipt)">
                    <img src={{row.url}}{{row.rent_receipt}}   style="cursor: pointer"  width="40" height="40">
                  </div>
                </ng-template>
              </ngx-datatable-column>
             
            </ngx-datatable>
          </div>
        </div>
      </section>
    </div>
  </div>
  
  <ng-template #modalImage let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Profile</h4>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body d-flex justify-content-center align-items-center">
      <swiper [config]="config" [pagination]="true" class="swiper_div">
        <div *ngFor="let data of image" class="center">
          <img style="object-fit: contain;width: 100%;"
            [src]="'https://meet.locatestudent.com/api/images/' + data"
            height="700"
          />
        </div>
      </swiper>
    </div>
  </ng-template>

  <ng-template #idcard let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Profile</h4>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body d-flex justify-content-center align-items-center">
      <swiper [config]="config" [pagination]="true" class="swiper_div">
        <div  class="center">
          <img style="object-fit: contain;width: 100%;"
            [src]="'https://meet.locatestudent.com/api/images/' + image "
            height="700"
          />
        </div>
      </swiper>
    </div>
  </ng-template>
  
  <ng-template #rateModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Rate User</h4>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body d-flex justify-content-center align-items-center">
      <form class="auth-login-form mt-2" [formGroup]="rateUser" (ngSubmit)="onSubmit(rateUser.value)">
        <div class="form-group row">
          <label for="first-name">Rate</label>
          <input
            type="number"
            id="propertyname"
            class="form-control"
            name="fname"
            formControlName="rate"
            placeholder="Rate Out Of 10"
          />
          <button class="btn btn-primary mt-2">
            <span>Submit</span>
          </button>
        </div>
      </form>
    </div>
    
  </ng-template>
  <!-- wrmgwkrmg -->
  