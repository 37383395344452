import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingVerificationComponent } from './pending-verification/pending-verification.component';
import { UnverifiedComponent } from './unverified/unverified.component';
import { VerifiedComponent } from './verified/verified.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes = [
  {
    path: 'pending-verification',
    component: PendingVerificationComponent,
    
  },
  {
    path: 'un-verified',
    component: UnverifiedComponent,
  },
  {
    path: 'verified',
    component: VerifiedComponent,
  },

];

@NgModule({
  declarations: [PendingVerificationComponent,
    UnverifiedComponent,
    VerifiedComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    NgxDatatableModule,
    CardSnippetModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [PendingVerificationComponent,UnverifiedComponent,VerifiedComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class VerificationModule { }
