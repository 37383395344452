import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "@core/api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { SwiperOptions } from "swiper";

@Component({
  selector: "app-pending-verification",
  templateUrl: "./pending-verification.component.html",
  styleUrls: ["./pending-verification.component.scss"],
})
export class PendingVerificationComponent implements OnInit {
  public contentHeader: object;
  searchtext;
  loading :any
  rows: any = [];
  image: any = [];
  data_loading: any;
  count: any;
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
  };
  public rateUser: FormGroup;
  page: any = "1";
  row_id: any;
  constructor(
    public service: ApiService,
    public modalService: NgbModal,
    private _formBuilder: FormBuilder,
    public toastx : ToastrService
  ) {
   
  }

  ngOnInit(): void {
    this.data_loading = true;
    this.service.peding_verification().subscribe((res) => {
      console.log(res);
      this.count = res["count"];
      this.rows = res["data"];
      this.data_loading = false;
    });
    this.contentHeader = {
      headerTitle: "Pending",
      actionButton: true,
    };
    this.rateUser = this._formBuilder.group({
      rate: ["", Validators.required],
    });
  }

  showimage(modalImage, row, rowIndex) {
    this.image = [];
    this.modalService.open(modalImage, {
      size: "md",
      centered: true,
      windowClass: " modal-Image",
    });
    this.image = row.image;
  }

  showidcard(idcard, row, rowIndex, data) {
    this.image = [];
    this.modalService.open(idcard, {
      size: "md",
      centered: true,
      windowClass: " modal-Image",
    });

    this.image = data;
  }

  onPage(event) {
    this.data_loading = true;
    this.page = event.offset + 1;
    this.service.peding_verification_by_page(this.page, "").subscribe((res) => {
      console.log(res);
      this.rows = res["data"];
      this.data_loading = false;
    });
  }

  rate(rateModal, row, rowIndex) {
    this.modalService.open(rateModal, {
      size: "sm",
      centered: true,
      windowClass: " modal-Image",
    });

    console.log(row);
    this.row_id = row.id;
  }

  onSubmit(value) {
    this.loading = true
    this.service.rate_user(value.rate, this.row_id).subscribe((res) => {
      this.loading = false
      if (res["result"] == true) {
        this.toastx.success("Ratting added succesfully");
        this.ngOnInit()
      }
      this.modalService.dismissAll();
    });
  }


  clearFilter(event) {
    console.log(this.page);

    this.data_loading = true;
    this.rows = [];
    this.searchtext = "";
    this.searchtext = event.target.value;
    this.service.get_user_list(this.page, this.searchtext).subscribe((res) => {
      this.rows = res["data"];
      this.data_loading = false;
    });
  }
}
