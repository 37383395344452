<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="basic-horizontal-layouts">
            <div class="row match-height">
                <div class="col-md-12 col-12 card">
                    <div class="table-responsive">
                        <div class="row" style="width: 100%">
                            <div class="col-12 mb-50">
                                <!-- <button (click)="add_property()" class="btn btn-primary ml-2 mb-3" rippleEffect>
                                    <i data-feather="plus" class="mr-0 mr-sm-1"></i><span
                                        class="d-none d-sm-inline-block">{{'menu.Add_Properties'|translate}}</span>
                                </button> -->
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="d-flex justify-content-between align-items-center m-1">
                                    <label class="d-flex align-items-center">
                                        <select class="form-control mx-25" style="width: 65px;">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                                <div
                                    class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                    <label class="d-flex align-items-center">Search<input type="search"
                                            placeholder="Search" class="form-control ml-25"
                                            autocomplete="off" /></label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="text-align: center;">Section</th>
                                <th style="text-align: center;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of sections_list; let i = index ">
                                <td style="text-align: center;">{{data.name}}</td>
                                <td style="text-align: center;">
                                    <button class="btn-primary btn" (click)="modalOpenEdit(modalEdit, data)">
                                        <i data-feather="edit-2"></i>
                                    </button>
                                    <button class="btn-danger btn ml-2" (click)="modalOpenDanger(modalDanger, data,i)">
                                        <i data-feather="trash-2"></i>
                                    </button>
                                </td>

                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </section>
    </div>
</div>


<!-- modal Edit -->

<ng-template #modalEdit let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel160">Edit Section</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form class="auth-login-form mt-2" [formGroup]="updateSection" (ngSubmit)="onSubmit(updateSection.value)">
            <div class="row">
                <div class="col-11">
                    <div class="form-group row">
                        <div class="col-sm-9">

                            <label for="first-name">Section</label>
                            <input type="text" [(ngModel)]="section_name" id="section_name" class="form-control" name="section"
                                formControlName="section" placeholder="Section name" />

                        </div>
                    </div>
                </div>
                <div class="col-11">
                    <button class="btn btn-dark">Update</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<!-- modal Edit -->


<!-- Del modal -->
<ng-template #modalDanger let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel160">Delete Section</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="1" ngbAutofocus>
        Are you sure you want to delete this Section?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="del()" rippleEffect>
            Accept
        </button>
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            Decline
        </button>
    </div>
</ng-template>
<!-- Del modal -->