import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@core/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-sos',
  templateUrl: './modal-sos.component.html',
  styleUrls: ['./modal-sos.component.scss']
})
export class ModalSosComponent implements OnInit {
  @Input() data: any;
   id :any
  constructor(public service : ApiService , public modalService : NgbModal , public toastx : ToastrService) { 
    
  }

  ngOnInit(): void {
    // console.log('Id:', this.data.id);
    this.id = this.data.id 
    console.log(this.id);
    
  }

  close(){
    this.modalService.dismissAll()
  }

  help(){
    this.service.seen_sos(this.id).subscribe(res=>{
      console.log(res);
      if(res['result']== true){
        this.modalService.dismissAll()
      }
      
    })
  }

}
