import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@core/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.scss']
})
export class AddSectionComponent implements OnInit {
  public contentHeader :any
  public addSection: FormGroup;
  admin_id : any
  constructor(public service : ApiService , public router : Router, public toastr : ToastrService,private _formBuilder: FormBuilder,) { 
    this.admin_id = window.localStorage.getItem('meet_admin_id')
    console.log(this.admin_id);
  
  }

  ngOnInit(){ 
    
    
    this.contentHeader = {
      headerTitle: 'Add Section',
      actionButton: true,
    }

    this.addSection = this._formBuilder.group({
      section: ['', Validators.required]
    });
  }



  onSubmit(value){
    console.log(value);
    this.service.add_section(value.section , this.admin_id).subscribe(res=>{
      console.log(res);

      if(res['result'] == true){
         this.toastr.success('Section Added Sucessfully ')
         this.router.navigate(['section-list'])
      }
      else{
        this.toastr.error('Enter Correct Name ')
      }
    })
  }

 
}
